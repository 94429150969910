import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, NavSidebar } from 'imdshared';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useCountActiveFieldUpdateRequests,
  useCurrentCustomer,
  useCurrentCustomerSubscription,
  useProductPrice,
} from 'imddata';

import styled from '@emotion/styled';
import NavItemLink, { NavItem, NavLabel } from './NavItemLink';
import NavHeader from './NavHeader';
import { FieldUpdateRequestWindowPopup } from '../FieldUpdateRequestWindowPopup';
import {
  NavHomeIcon,
  NavAccountIcon,
  NavProductsIcon,
  NavRevenueIcon,
  NavLibraryIcon,
  NavAnalyticsIcon,
  ChevronRightIcon,
  accentThemes,
  AmpStrokeIcon,
} from '@imus/base-ui';
import { HelpCenterButton } from './HelpCenterButton';
import { AmpsTeaserWindow } from './AmpsTeaserWindow';
import qs from 'qs';
import { motion } from 'framer-motion';
import { css } from '@emotion/react';
import PersonalConsulationWindow from './PersonalConsulationWindow';

const FooterLink = styled(NavItem)`
  border: 1px solid var(--error, #e32e2a);
  background: var(--error-surface-container, #fffbff);
`;

const FieldUpdateRequestLink = function () {
  const { t } = useTranslation();
  const count = useCountActiveFieldUpdateRequests();
  const history = useHistory();
  if (!count) return null;
  return (
    <>
      <FieldUpdateRequestWindowPopup count={count} />
      <FooterLink
        selected={false}
        onClick={() => {
          history.push('/update-requests');
        }}
        text={
          <>
            {t('update-requests')}
            <ChevronRightIcon />
          </>
        }
      ></FooterLink>
    </>
  );
};

const FooterWrapper = styled(motion.div)`
  padding: 40px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  & > * {
    width: 100%;
  }
`;
const DisplayedButtons = styled(motion.div)`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

function NavFooter() {
  const productPrice = useProductPrice('phone-consultation');
  const enabled = !!productPrice?.price;
  const timer = useRef<number>();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const handleMouseOver = useCallback(() => {
    if (!enabled) return;
    clearTimeout(timer.current);
    setVisible(true);
  }, [enabled]);
  const handleMouseLeave = useCallback(() => {
    timer.current = window.setTimeout(() => setVisible(false), 1000);
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);
  const { t } = useTranslation();
  return (
    <ErrorBoundary>
      <FooterWrapper
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        initial={false}
        animate={{ y: visible ? -8 : 0 }}
      >
        <DisplayedButtons>
          <FieldUpdateRequestLink />
          <HelpCenterButton />
        </DisplayedButtons>
        <ErrorBoundary>
          <PersonalConsulationWindow
            isOpen={open}
            onClose={() => setOpen(false)}
          />
        </ErrorBoundary>
        <NavItem
          css={css`
            text-align: center;
            opacity: ${visible ? '1' : '0'};
            pointer-events: ${enabled ? 'all' : 'none'};
            transition: opacity 0.1s ease-in-out;
          `}
          selected={false}
          text={t('personal-consultation')}
          onClick={() => {
            setOpen(true);
          }}
        />
      </FooterWrapper>
    </ErrorBoundary>
  );
}

const components = { NavHeader, NavFooter };

const NavGrid = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  row-gap: 12px;
  padding: 32px 20px;
`;

const AmpChipWrapper = styled.div`
  position: absolute;
  padding: 8px;
  top: 0px;
  right: 0;
  cursor: pointer;
  z-index: 2;
  &:hover {
    opacity: 0.8;
  }
`;

const AmpChip = styled.div`
  ${accentThemes.tertiary}
  ${NavLabel} {
    color: var(--on-accent, #fffbff);
  }
  svg {
    stroke: var(--on-accent, #fffbff);
    width: 16px;
    height: 16px;
  }
  svg path {
    stroke: var(--on-accent, #fffbff);
    stroke-width: 1.5px;
  }
  display: inline-flex;
  height: 24px;
  padding: 0px 8px;
  align-items: center;
  gap: 1px;
  flex-shrink: 0;

  border-radius: 16px;
  background: var(--accent, #b48b00);
`;

function NavDrawer() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const customerSubscription = useCurrentCustomerSubscription();
  const location = useLocation();
  const history = useHistory();
  const { entry: customer } = useCurrentCustomer();

  const creditsValue = customer?.credits?.find(
    (c) => c.currencyId === 'amp'
  )?.amount;
  const excludedFromAmps =
    customerSubscription?.subscriptionId === 'vip' ||
    customerSubscription?.subscriptionId === 'professional-unlimited' ||
    customerSubscription?.subscriptionId === 'professional-unlimited-analytics';

  const params = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }, [location.search]);

  useEffect(() => {
    if (params.openAmps) {
      setIsOpen(true);
    }
  }, [params.openAmps]);

  return (
    <NavSidebar components={components}>
      <NavGrid>
        <NavItemLink
          to="/"
          matchPath="(/|/dashboard)"
          strict={true}
          text={t('dashboard')}
          testId="NavItem-Dashboard"
          icon={<NavHomeIcon />}
        />

        <NavItemLink
          to="/library"
          text={t('library')}
          icon={<NavLibraryIcon />}
          testId="NavItem-Library"
        ></NavItemLink>

        <NavItemLink
          to="/products"
          matchPath="(/products)"
          text={t('products')}
          icon={<NavProductsIcon />}
        />

        <NavItemLink
          to="/reporting"
          text={t('analytics')}
          icon={<NavAnalyticsIcon />}
        ></NavItemLink>

        <NavItemLink
          to="/revenue"
          text={t('revenue')}
          icon={<NavRevenueIcon />}
        ></NavItemLink>

        <div style={{ position: 'relative' }}>
          <NavItemLink
            to="/account"
            text={t('account')}
            testId="NavItem-Account"
            icon={<NavAccountIcon />}
          />
          {!excludedFromAmps && (
            <AmpChipWrapper
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <AmpChip>
                <AmpStrokeIcon> </AmpStrokeIcon>
                <NavLabel>{creditsValue || 0}</NavLabel>
              </AmpChip>
            </AmpChipWrapper>
          )}
        </div>
      </NavGrid>
      <AmpsTeaserWindow
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
          // debugger;
          if (params.openAmps) {
            history.push(location.pathname);
          }
        }}
      />
    </NavSidebar>
  );
}
export default memo(NavDrawer);
