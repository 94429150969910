import { useTranslation } from 'next-i18next';
import { SurfaceCard, Text, TourItem } from '@imus/services-ui';
import Image from 'next/image';
import styled from '@emotion/styled';
import type { TemplateData, TourPageSplash } from '../types';
import { useBaseUrl } from '../BaseUrlContext';
import { getFileUrl } from '../utils/getFileUrl';
import { css } from '@emotion/react';
import { LinkButton } from 'imdui';
import { Separator } from '../Separator';

const TourCard = styled(SurfaceCard)`
  display: grid;
  gap: 16px;
  padding: 16px;
`;

const cardStyle = css`
  flex-direction: column;
  padding: 16px;
  overflow: hidden;
`;

const tourHeaderRowStyle = css`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

const imageStyle = css`
  max-height: 246px;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 8px;
`;

export const TourHeader = ({
  image,
  title,
  description,
  url,
}: TourPageSplash) => {
  const baseUrl = useBaseUrl();
  const { t } = useTranslation();
  return (
    <SurfaceCard css={cardStyle}>
      <div css={tourHeaderRowStyle}>
        <div>
          <Text.H3>{title}</Text.H3>
          <Text.ContentM data-test-id="TourHeader-description">
            {description}
          </Text.ContentM>
        </div>
        {url && (
          <LinkButton
            css={css`
              background: none;
              flex-grow: 1;
              flex: 80px;
              background: none;
            `}
            appearance="stroke"
            text={t('visit')}
            size="small"
            primary
            external
            to={url}
          ></LinkButton>
        )}
      </div>
      {image && (
        <Image
          css={imageStyle}
          src={getFileUrl(image, baseUrl)}
          width={600}
          objectFit="cover"
          objectPosition="center"
          height={256}
          alt={description || 'gallery'}
        />
      )}
    </SurfaceCard>
  );
};

export const TourInfo = ({
  tourEvents,
  locale,
  tourHighlight,
}: {
  locale: string;
  tourHighlight?: TemplateData['tour']['splash'];
  tourEvents: TemplateData['tour']['events'];
}) => {
  return (
    <>
      {tourHighlight ? <TourHeader {...tourHighlight} /> : null}

      {tourEvents?.length ? (
        <TourCard>
          {tourEvents
            .sort(
              (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
            )
            .map((te, idx) => (
              <>
                <TourItem key={idx} event={te} locale={locale} />
                {idx !== tourEvents.length - 1 && <Separator />}
              </>
            ))}
        </TourCard>
      ) : null}
    </>
  );
};
