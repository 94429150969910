import styled from '@emotion/styled';
import { Button, TextFormatted, Window } from 'imdui';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import avatarUrl from './avatars/1.png';
// @ts-ignore
import avatarUrl2 from './avatars/2.png';
// @ts-ignore
import avatarUrl3 from './avatars/3.png';
// @ts-ignore
import avatarUrl4 from './avatars/4.png';
// @ts-ignore
import avatarUrl5 from './avatars/5.png';
import { accentThemes, ContentM } from '@imus/base-ui';
import { useProductPriceFormatted } from 'imdshared';
import { useAmpPrice, useProductPrice } from 'imddata';
import { useInitFastPayment } from 'components/hooks';
import { SmallPriceAmpChip } from 'components';

const Description = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 8px 32px 24px 32px;
  gap: 16px;
`;
const ActionWrapper = styled.div`
  ${accentThemes.tertiary}
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 8px;
`;
const AvatarRow = styled.div`
  --gap: 12px; /* 👈 gap control */
  display: flex;
  flex-wrap: wrap;
  filter: grayscale(1);
  margin-left: 12px;

  & > * {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-left: -12px;
  }
  & img:nth-child(4) {
    z-index: 1;
  }
  & img:nth-child(3) {
    z-index: 2;
  }
`;

const WindowStyled = styled(Window)`
  max-width: 480px;
  border-radius: 16px;
  border: 1px solid var(--outline-var, #cdcbc9);
  background: var(--surface-container-medium, #f4f1f0);

  /* Shadow/3 */
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 4px 10px -2px rgba(0, 0, 0, 0.1);
`;

export default function PersonalConsulationWindow({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const price = useProductPriceFormatted('phone-consultation');
  const ampPrice = useAmpPrice('phone-consultation');
  const productPrice = useProductPrice('phone-consultation');
  const { init, loaded } = useInitFastPayment({
    productId: 'phone-consultation',
    successPath: '/account/invoices',
    successMessage: t('personal-consultation-requested-next-steps'),
  });
  return (
    <WindowStyled
      headerAlign="center"
      title={t('personal-consultation')}
      isOpen={isOpen}
      close={onClose}
    >
      <Description>
        <AvatarRow>
          <img src={avatarUrl4} alt="avatar" />
          <img src={avatarUrl2} alt="avatar" />
          <img src={avatarUrl5} alt="avatar" />
          <img src={avatarUrl} alt="avatar" />
          <img src={avatarUrl3} alt="avatar" />
        </AvatarRow>
        <ContentM>
          <TextFormatted text={t('personal-consultation-description')} />
        </ContentM>
      </Description>
      <ActionWrapper>
        <Button
          text={t('personal-consultation-request')}
          primary
          disabled={!loaded}
          showLoading={!loaded}
          appearance="fill"
          style={{ width: '100%' }}
          onClick={() => {
            init({});
            onClose();
          }}
        />
        {productPrice?.price && Number(productPrice.price.price) > 0 ? (
          <SmallPriceAmpChip price={price} ampPrice={ampPrice} />
        ) : null}
      </ActionWrapper>
    </WindowStyled>
  );
}
