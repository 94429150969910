import {
  BuyOrSubOffer,
  EnabledField,
  useProductPriceFormatted,
} from 'imdshared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useDates } from './hooks';
import type { ReduxState } from 'imddata';
import {
  useAmpPrice,
  useCustomerAmpBalance,
  useCustomerFeature,
} from 'imddata';
import type { Values } from './types';
import { ContinueWindow } from 'components/molecules';
import { useTranslation } from 'react-i18next';
import { SmallPriceAmpChip } from 'components/atoms';
import { css } from '@emotion/react';
const emptyShops: never[] = [];

export const SpotifyPitchToggle = ({
  ...props
}: React.ComponentProps<typeof EnabledField>) => {
  const { form } = props.meta;
  const selector = useMemo(() => formValueSelector(form), [form]);
  const shops: Values['shops'] = useSelector((state: ReduxState) =>
    selector(state, 'shops')
  );

  const enableEditorialPlaylistPitching: Values['shops'] = useSelector(
    (state: ReduxState) => selector(state, 'enableEditorialPlaylistPitching')
  );
  const { onClearDeliveryDates } = useDates({ disabled: props.disabled, form });

  const hasSpotify =
    shops?.shops?.find((s) => s.shopId === 'spotify')?.status === 'deliver';

  const pitchingShops = props.input.value?.shops || emptyShops;

  // TODO: figure out better types for it
  const value =
    !!pitchingShops.find((s: string) => s === 'spotify') ||
    enableEditorialPlaylistPitching;

  const handleChange = useCallback(
    (checked) => {
      onClearDeliveryDates();
      if (checked) {
        props.input.onChange({ shops: [...pitchingShops, 'spotify'] });
        return;
      }
      props.input.onChange({
        shops: pitchingShops.filter((s: string) => s !== 'spotify'),
      });
    },
    [onClearDeliveryDates, props.input.onChange, pitchingShops]
  );

  useEffect(() => {
    if (!hasSpotify && value) {
      // TODO: may be we need to unset it?
      // Do not change spotify shop info

      handleChange(false);
    }
  }, [hasSpotify]);
  const { t } = useTranslation();

  const input = useMemo(() => {
    return {
      ...props.input,
      value,
      onChange: handleChange,
    };
  }, [handleChange, props.input, value]);
  return (
    <EnabledField
      {...props}
      label={t('spotify-pitching')}
      description={t('has-spotify-pitching', {
        context: enableEditorialPlaylistPitching
          ? 'with-playlist-pitching'
          : undefined,
      })}
      disabled={
        !hasSpotify || props.disabled || !!enableEditorialPlaylistPitching
      }
      input={input}
    />
  );
};

export const PlaylistPitchingToggle = ({
  ...props
}: React.ComponentProps<typeof EnabledField>) => {
  const [decideBuy, setDecideBuy] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const { t } = useTranslation();
  const { form } = props.meta;

  const pitchingPrice = useProductPriceFormatted('playlist-pitching');
  const ampPrice = useAmpPrice('playlist-pitching');
  const amps = useCustomerAmpBalance();
  const hasFeature = useCustomerFeature('playlist-pitching'); // T2 TODO: change
  const { onClearDeliveryDates } = useDates({ form, disabled: props.disabled });

  const hasEnoughAmpsForProduct = ampPrice && amps && amps >= ampPrice;

  const handleChange = useCallback(
    (checked) => {
      if (!hasFeature && !decideBuy && checked && !hasEnoughAmpsForProduct) {
        setOpenWindow(true);
        return;
      }
      setOpenWindow(false);
      setDecideBuy(false);
      onClearDeliveryDates();
      props.input.onChange(checked);
    },
    [onClearDeliveryDates, hasFeature, decideBuy, props.input.onChange]
  );

  const input = useMemo(() => {
    return {
      ...props.input,
      onChange: handleChange,
    };
  }, [handleChange, props.input]);
  return (
    <>
      <ContinueWindow
        isOpen={openWindow}
        disabled={!decideBuy}
        onCancel={() => {
          setOpenWindow(false);
        }}
        onContinue={() => {
          handleChange(true);
        }}
        title={props.label || ''}
      >
        <BuyOrSubOffer
          subscribeContext={{ analytics: { detail: 'pitching' } }}
          price={pitchingPrice}
          title={t('unlock-playlist-pitching')}
          description={t('unlock-playlist-pitching-desc')}
          onDecideSubscribe={() => {
            setOpenWindow(false);
          }}
          onDecideBuy={setDecideBuy}
          decideBuy={decideBuy}
        />
      </ContinueWindow>
      <EnabledField
        {...props}
        disabled={props.disabled}
        caption={
          hasEnoughAmpsForProduct && !hasFeature ? (
            <SmallPriceAmpChip
              css={css`
                justify-content: flex-start;
                margin-top: 16px;
              `}
              price={pitchingPrice}
              ampPrice={ampPrice}
            />
          ) : null
        }
        input={input}
      />
    </>
  );
};
