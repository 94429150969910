import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { accentThemes, AmpStrokeIcon } from '@imus/base-ui';

const PriceChipText = styled.span`
  font-family: Barlow;
  font-size: var(--price-chip-text, 15px);
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 15px */
  letter-spacing: 0.3px;
`;

const priceContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--price-chip-gap, 4px);
`;

const priceDivider = css`
  color: var(--outline-var, #cdcbc9);
  text-align: center;
`;

export function SmallPriceAmpChip({
  price,
  style,
  className,
  ampPrice,
}: {
  style?: React.CSSProperties;
  className?: string;
  price?: string;
  ampPrice?: string | number | null;
}) {
  return (
    <div css={priceContainer} style={style} className={className}>
      {price && <PriceChipText>{price}</PriceChipText>}
      {price && ampPrice ? (
        <PriceChipText css={priceDivider}>/</PriceChipText>
      ) : null}
      {ampPrice ? (
        <span
          css={css`
            display: inline-flex;
            align-items: center;
            ${accentThemes.tertiary}
          `}
        >
          <AmpStrokeIcon
            css={css`
              --on-surface: var(--accent);
            `}
            width={16}
            height={16}
          />

          <PriceChipText
            css={css`
              color: var(--accent);
            `}
          >
            {ampPrice}
          </PriceChipText>
        </span>
      ) : null}
    </div>
  );
}
