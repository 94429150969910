import React, { useContext, useEffect, useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useParams, Redirect } from 'react-router-dom';
import type { EntityModels } from 'imddata';
import {
  useEntries,
  useEntryProvider,
  useUpdateEntity,
  useIsUploadingTracks,
  useInstantMastering,
} from 'imddata';
import { ProductLoader, ProductPageContext } from 'components';
import NavBar from '../../shared/NavBar';
import type { ProductPageContextType } from '../../shared/ProductWizard';
import {
  ProductWizard,
  ProductPriceProvider,
  useProductPageActions,
  useProductPage,
  useProductId,
} from '../../shared/ProductWizard';
import {
  MasteringTracksManager,
  isTrackValid,
  Delivery,
  Summary,
} from './screens';
import {
  PriceBreakdown,
  PriceIconPortal,
  ProductPriceContext,
} from 'screens/Order/shared';
import { useTranslation } from 'react-i18next';
import {
  useBreakdownItemsFromOverview,
  useOverviewItems,
} from '../MusicDistribution/PriceBreakdown';
import { AlertBox } from 'imdui';

// const requiredFields = ['uploadStatus', 'title'];
//
//

const emptyTracks: never[] = [];

const TRACKS_DETAILS = 'tracks-details';
const SUMMARY = 'previews';
const MASTERING_OPTIONS = 'options';

const MasteringManager = () => {
  const { changeStep } = useProductPageActions();
  const { id } = useProductPage();

  const { entry } = useInstantMastering({
    id,
  });

  const {
    request: { updating },
  } = useUpdateEntity({
    entity: 'instantMasterings',
    id,
  });

  const trackIds = entry?.tracks.map(({ trackId }) => trackId) || emptyTracks;

  const { entries: tracks } = useEntries<EntityModels.Track>({
    entity: 'tracks',
    ids: trackIds,
  });

  const valid = useMemo(
    () =>
      tracks?.reduce((acc, track) => {
        return acc && isTrackValid(track);
      }, true),
    [tracks]
  );

  useEffect(() => {
    changeStep({
      stepId: MASTERING_OPTIONS,
      payload: {
        valid: true,
      },
    });
  }, []);

  useEffect(() => {
    changeStep({
      stepId: TRACKS_DETAILS,
      payload: {
        valid,
      },
    });
  }, [valid]);

  const isTracksProcessing = useMemo(
    () =>
      tracks?.reduce(
        (acc, track) => acc || track.uploadStatus === 'confirmed',
        false
      ),
    [tracks]
  );

  const isTracksUploading = useIsUploadingTracks({ ids: trackIds });

  useEffect(() => {
    changeStep({
      stepId: TRACKS_DETAILS,
      payload: {
        verifying: isTracksProcessing || isTracksUploading || updating,
      },
    });
  }, [isTracksProcessing, updating, isTracksUploading]);

  return null;
};

const useSetMasteringToOpen = ({
  currentStepId,
  id,
}: {
  currentStepId: string;
  id: string | number;
}) => {
  const { changeStep } = useProductPageActions();
  const entry = useEntryProvider<EntityModels.InstantMastering>({
    id,
    entity: 'instantMasterings',
  });
  const { updateEntry } = useUpdateEntity({
    entity: 'instantMasterings',
    id,
    query: { with: 'tracks' },
  });

  useEffect(() => {
    if (currentStepId !== SUMMARY && entry?.status === 'previewing') {
      updateEntry({ data: { status: 'open' } });
      changeStep({ stepId: SUMMARY, payload: { visited: false } });
    }
  }, [currentStepId]);

  return entry?.status;
};

const SUMMARY_ROUTE = 'previews';
const TRACKS_DETAILS_ROUTE = TRACKS_DETAILS;
const MASTERING_STEPS = [
  { id: TRACKS_DETAILS, label: 'tracks-details' },
  { id: MASTERING_OPTIONS, to: 'options', label: 'mastering-options' },
  { id: SUMMARY, to: SUMMARY_ROUTE, label: 'mastering-previews' },
];

export const MASTERING_CONTEXT: Omit<ProductPageContextType, 'id'> = {
  product: 'mastering',
  title: 'instant-mastering',
  steps: MASTERING_STEPS,
  initialStepsState: MASTERING_STEPS,
  entity: 'instantMasterings',
  overviewEntity: 'instantMasteringOverview',
  StepperStateManager: MasteringManager,
  NavBarRenderer: function NavBarRenderer({ stepId, ...props }) {
    switch (stepId) {
      case MASTERING_OPTIONS:
        return <NavBar {...props} next={undefined} />;
      default:
        return <NavBar {...props} />;
    }
  },
  RedirectManager: function RedirectManager({ match, id }) {
    const entry = useEntryProvider<EntityModels.InstantMastering>({
      id,
      entity: 'instantMasterings',
    });
    if (!entry) return null;

    if (entry.status === 'previewing') {
      return <Redirect to={`${match.url}/${SUMMARY_ROUTE}`} />;
    }

    return <Redirect to={`${match.url}/${TRACKS_DETAILS_ROUTE}`} />;
  },
  StepRenderer: function StepsRenderer({ stepId, ...props }) {
    const id = useProductId();
    const { t } = useTranslation();
    const masteringNotice = t('im-maintenance-notice', {
      defaultValue: '',
    });

    const status = useSetMasteringToOpen({ currentStepId: stepId, id });

    let step: React.ReactNode;

    switch (stepId) {
      case TRACKS_DETAILS: {
        if (status === 'previewing') step = <ProductLoader />;
        step = <MasteringTracksManager id={id} {...props} />;
        break;
      }
      case MASTERING_OPTIONS: {
        // status previewing is handled inside Delivery
        step = <Delivery id={id} {...props} />;
        break;
      }
      case SUMMARY:
        step = <Summary id={id} {...props} />;
        break;
      default:
        step = <span>{`${stepId} :not-found`}</span>;
    }
    return (
      <>
        {masteringNotice ? (
          <AlertBox
            style={{ marginBottom: '12px' }}
            type={AlertBox.Type.warning}
            text={masteringNotice}
          />
        ) : null}
        {step}
      </>
    );
  },
};

const PricePortal = () => {
  const { t } = useTranslation();
  const { overview } = useContext(ProductPriceContext);
  const overviewItems = useOverviewItems(overview);
  const masteringItemOverview = overviewItems.find(
    (oi) => oi.id === 'mastering'
  );

  const items = useMemo(
    () => [
      {
        label: `${t('tracks', {
          count: masteringItemOverview?.quantity,
        })} (${masteringItemOverview?.quantity})`,
        id: 'mastering',
      },
    ],
    [masteringItemOverview]
  );

  const breakdownItems = useBreakdownItemsFromOverview(items);

  return (
    <PriceIconPortal>
      <PriceBreakdown items={breakdownItems} />
    </PriceIconPortal>
  );
};

export default function Mastering(props: RouteComponentProps) {
  const { id } = useParams<{ id: string }>();

  const value = useMemo(() => {
    return {
      id,
      ...MASTERING_CONTEXT,
    };
  }, [id]);

  const {
    request: { loaded },
  } = useInstantMastering({ id });

  return (
    <ProductPageContext.Provider value={value}>
      <ProductPriceProvider>
        <PricePortal />
        <ProductWizard loading={!loaded} {...props} />
      </ProductPriceProvider>
    </ProductPageContext.Provider>
  );
}
