import type { EntityModels } from 'imddata';
import { createContext, useCallback, useContext, useState } from 'react';

type Context =
  | {
    loading: true;
    failed?: undefined;
    orderStatus?: undefined;
    orderId?: undefined;
    successPath?: undefined;
    successMessage?: undefined;
  }
  | {
    loading: false;
    failed: true;
    successPath?: string;
    orderId?: undefined;
    successMessage?: undefined;
    orderStatus?: undefined;
  }
  | {
    loading: false;
    failed?: false;
    successPath?: string;
    successMessage?: React.ReactNode;
    orderId?: number;
    orderStatus: EntityModels.Order['status'] | undefined;
  };
type ContextValue = {
  open: (c?: Context) => void;
  close: () => void;
  isOpened: boolean;
  context: Context;
};

export const FastPaymentContext = createContext<ContextValue>({
  open: () => undefined,
  close: () => undefined,
  isOpened: false,
  context: {
    loading: false,
    orderStatus: undefined,
  },
});

FastPaymentContext.displayName = 'FastPaymentContext';

export const useFastPaymentFlowContext = () => {
  return useContext(FastPaymentContext);
};

export const useFastPaymentFlowManager = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [context, setContext] = useState<Context>({
    loading: false,
    orderStatus: undefined,
  });

  const open = useCallback((c?: Context) => {
    setIsOpened(true);
    if (c) {
      setContext(c);
    } else {
      setContext({ loading: false, orderStatus: undefined });
    }
  }, []);

  const close = useCallback(() => {
    setIsOpened(false);
  }, []);
  return {
    context,
    isOpened,
    open,
    close,
  };
};
