import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { themeLight as theme } from 'imdui';
import type { Moment } from 'moment';
import type { ModifiersShape } from 'react-dates';

type Props = {
  testId?: string;
  testValue?: string;
  day: Moment;
  modifiers?: ModifiersShape;
  deliveryOption?: { id: string };
  isProcessing?: boolean;
  onHovered?: (day: Moment) => void;
};

const DayNumber = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-feature-settings:
    'ss01' on,
    'cv11' on;

  /* Text/M/Content */
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.225px;
`;

const Day = styled.span<{
  modifiers?: Props['modifiers'];
  deliveryOption: Props['deliveryOption'];
}>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: ${({ modifiers }) => {
    if (modifiers?.has('today')) {
      return '50%';
    }

    return '8px';
  }};
  font-size: 14px;
  font-weight: ${({ modifiers }) => (modifiers?.has('selected') ? 700 : 400)};
  color: ${({ modifiers }) => {
    if (modifiers?.has('selected-start') || modifiers?.has('selected-end')) {
      return '#fff';
    }
    return 'inherit';
  }};
  background-color: ${({ modifiers, deliveryOption }) => {
    if (modifiers?.has('selected-start') || modifiers?.has('selected-end')) {
      return theme.accent.green;
    }
    if (
      modifiers &&
      (modifiers.has('hovered-span') ||
        modifiers.has('after-hovered-start') ||
        modifiers.has('before-hovered-end') ||
        modifiers?.has('selected-span'))
    ) {
      return theme.lights[2];
    }
    if (modifiers?.has('blocked')) {
      return 'none';
    }
    if (deliveryOption?.id === 'ultraexpressdelivery') {
      return 'var(--state-active-accent)';
    }
    if (deliveryOption?.id === 'expressdelivery') {
      return 'var(--state-active-on-surface)';
    }
    return '';
  }};

  ${DayNumber} {
    color: ${({ modifiers }) =>
    modifiers?.has('blocked-out-of-range') || modifiers?.has('blocked')
      ? 'var(--on-surface-var-2)'
      : 'var(--on-surface)'};
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: ${({ modifiers }) =>
    modifiers?.has('selected') ? 'rgba(0, 0, 0, 0.1)' : 'none'};
  }

  :hover {
    ::after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  :active {
    ::after {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

const DayRenderer = ({
  testId,
  testValue,
  day,
  modifiers,
  deliveryOption,
  onHovered,
}: Props) => {
  useEffect(() => {
    if (onHovered && modifiers && modifiers.has('hovered')) {
      onHovered(day);
    }
  }, [modifiers && modifiers.has('hovered')]);
  return (
    <Day
      data-test-id={testId || `Day-${day.format('YYYY-MM-DD')}`}
      data-test-value={testValue}
      modifiers={modifiers}
      deliveryOption={deliveryOption}
    >
      <DayNumber>{day.format('D')}</DayNumber>
    </Day>
  );
};

export default DayRenderer;
