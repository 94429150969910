import React from 'react';
import styled from '@emotion/styled';
import { H2, BodySmall } from 'imdui';

interface Props {
  title: string;
  text: string;
}

const Wrapper = styled.div`
  margin: 12px 0 24px 0;

  ${H2} {
    margin-bottom: 16px;
  }

  ${BodySmall} {
    display: block;
    max-width: 800px;
  }
`;

export default function DescriptionBlock({ title, text }: Props): JSX.Element {
  return (
    <Wrapper>
      <H2>{title}</H2>
      <BodySmall>{text}</BodySmall>
    </Wrapper>
  );
}
