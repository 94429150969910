/* eslint-disable react/jsx-no-undef */
import React, { memo } from 'react';
import GeneralForm from './GeneralForm';
import styled from '@emotion/styled';

import type { GeneralFormData } from './GeneralForm';
import type { ReleaseFormData } from './ReleaseForm';
import ReleaseForm from './ReleaseForm';

export type FormData = {
  general: GeneralFormData;
  release: ReleaseFormData;
};

const SideEditorContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
`;

type Props = {
  artistIds: Array<number>;
  form: string;
  id: string;
};

const FormContainer = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const SideEditor = ({ id: releasePageId, form }: Props) => {
  return (
    <SideEditorContainer data-test-id="TemplateSideEditor-FormContainer">
      <FormContainer visible={true}>
        <ReleaseForm releasePageId={releasePageId} form={form} />
        <GeneralForm releasePageId={releasePageId} />
      </FormContainer>
    </SideEditorContainer>
  );
};

export default memo(SideEditor);
