import { DeleteIcon } from '@imus/base-ui';
import { useDeleteEntity, useArtist } from 'imddata';
import { ConfirmationWindow } from 'imdshared';
import { Button, HelperText } from 'imdui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DeleteArtistButon = ({
  id,
  onDeleted,
}: {
  id: number;
  onDeleted: () => void;
}) => {
  const { entry: artist } = useArtist({ id, passive: true });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { t } = useTranslation();
  const {
    deleteEntry,
    request: { deleted, deleteErrorMessage, deleting },
  } = useDeleteEntity({ entity: 'artists', id });

  useEffect(() => {
    if (deleted) {
      setOpenConfirmation(false);
      onDeleted();
    }
  }, [deleted]);

  return (
    <>
      <Button
        iconLeft={DeleteIcon}
        text={t('delete-artist', {
          count: 1,
        })}
        style={{ width: '100%' }}
        disabled={deleting || artist?.deliveryStatus !== 'not_delivered'}
        onClick={() => {
          setOpenConfirmation(true);
        }}
      />
      <ConfirmationWindow
        loading={deleting}
        disabled={deleting}
        headerAlign="center"
        message={t('delete-artist-confirmation')}
        title={t('delete-artist')}
        isOpen={openConfirmation}
        onConfirm={() => {
          deleteEntry({ id });
        }}
        onRequestClose={() => {
          setOpenConfirmation(false);
        }}
      ></ConfirmationWindow>
      {deleteErrorMessage && <HelperText errorText={deleteErrorMessage} />}
    </>
  );
};
