import type { EntityModels } from 'imddata';
import { useCallback, useEffect } from 'react';
import { useCreateOrder, useCustomerProduct, useEntryProvider } from 'imddata';
import { useFastPaymentFlowContext } from 'imdshared';
import { EventTypes } from 'redux-segment';

const useCreateCustomProductOrder = ({
  productId,
  componentId,
}: {
  productId: string;
  componentId?: string;
}) => {
  const {
    createOrder,
    request: { creating, created, failed, id: orderId },
  } = useCreateOrder({
    entity: 'products',
    requestStoreKey: componentId,
    id: productId,
  });

  const handleClick = useCallback(
    (data) => {
      createOrder(
        {
          id: productId,
          query: {
            with: 'items',
          },
          ...data,
        },
        {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: 'FT Order Product',
              properties: {
                product: productId,
                ...(data?.data || {}),
              },
            },
          },
        }
      );
    },
    [productId]
  );
  return {
    orderId,
    createOrder: handleClick,
    request: {
      creating,
      created,
      failed,
    },
  };
};

export const useInitFastPayment = ({
  productId,
  successPath,
  successMessage,
}: {
  productId: string;
  successPath: string;
  successMessage?: React.ReactNode;
}) => {
  const {
    request: { loaded },
    product,
  } = useCustomerProduct(productId);
  const { createOrder, request, orderId } = useCreateCustomProductOrder({
    productId,
  });
  const { open: openPayment } = useFastPaymentFlowContext();

  const order = useEntryProvider<EntityModels.Order>({
    entity: 'orders',
    id: orderId,
  });
  useEffect(() => {
    if (order) {
      openPayment({
        loading: false,
        successPath,
        successMessage,
        orderStatus: order.status,
        orderId: orderId,
      });
    }
  }, [!!order]);

  useEffect(() => {
    if (request.failed) {
      openPayment({
        loading: false,
        failed: true,
      });
    }
  }, [request.failed]);

  const init = useCallback((data) => {
    openPayment({
      loading: true,
    });
    createOrder({
      data,
    });
  }, []);
  return {
    loaded,
    product,
    init,
  };
};
