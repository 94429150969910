import { useCurrentCustomerSubscription, useCustomerFeature } from 'imddata';
import type { SubscriptionId } from 'imddata/types/entities';
import { useMemo } from 'react';

const getLimit = (
  pro: boolean,
  proUnbranded: boolean,
  sub?: SubscriptionId
) => {
  const defaultLimit = proUnbranded ? 5 : pro ? 2 : 1;
  if (!sub) {
    return defaultLimit;
  }
  switch (sub) {
    case 'trial-monthly':
    case 'trial-yearly':
    case 'music-analytics':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
      return defaultLimit;

    case 'tier-1':
      return 2;

    case 'trial-tier-2':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'tier-3':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return Number.POSITIVE_INFINITY;
  }
};

export const useCustomerArtistPageLimits = () => {
  const tier1 = useCustomerFeature('artist-hub-pro');
  const tier2 = useCustomerFeature('artist-hub-pro-unbranded');
  const sub = useCurrentCustomerSubscription();
  const free = !tier1 && !tier2;
  return useMemo(
    () =>
      ({
        upgradable: free,
        publishedLimit: getLimit(tier1, tier2, sub?.subscriptionId),
        branded: !tier1 && !tier2,
        about: {
          enabled: true,
          allowedMediaTypes: free ? ['image-gallery', 'social'] : undefined,
        },
        releases: { enabled: true },
        tour: { enabled: !free },
        press: { enabled: !free },
      }) as const,
    [tier1, tier2, free]
  );
};
