// @flow
import type { MouseEventHandler } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import type { WrappedFieldProps } from 'redux-form';
import type { ToggleSwitchProps } from 'imdui';
import { HelperText, BodySmall, Content, Caption } from 'imdui';
import { useTranslation } from 'react-i18next';
import ToggleSwitchField from './ToggleSwitchField';
import { FieldCardAction } from 'imdui/src/components/field/FieldCard';

type Props = {
  onClickHelp?: MouseEventHandler;
  label?: string;
  description?: string;
  caption?: React.ReactNode;
  footer?: React.ReactNode;
} & ToggleSwitchProps &
  WrappedFieldProps;

export const EnabledFieldGroup = styled.div`
  & > div:first-child ${FieldCardAction} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > div:last-child ${FieldCardAction} {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const EnabledField: React.FC<Props> = ({
  label,
  description,
  caption,
  disabled,
  style,
  meta,
  components,
  helperText,
  warningText,
  footer,
  onClickHelp,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div style={style}>
      <FieldCardAction
        onClick={
          !disabled
            ? () => {
                props.input.onChange(!props.input.value);
              }
            : undefined
        }
        onClickHelp={onClickHelp}
        status={meta.error ? 'error' : undefined}
        action={
          <ToggleSwitchField
            meta={{ ...meta, error: undefined }}
            disabled={disabled}
            {...props}
          />
        }
      >
        <div>
          {label && (
            <div>
              <Content style={{ marginBottom: description ? '8px' : '0' }}>
                {label}
              </Content>
            </div>
          )}
          {description && <BodySmall>{description}</BodySmall>}
          {typeof caption === 'string' ? <Caption>{caption}</Caption> : caption}
        </div>
      </FieldCardAction>

      <HelperText
        errorText={
          meta.error === 'required' || !meta.error ? undefined : t(meta.error)
        }
      />
      {components?.OverlineActions ? <components.OverlineActions /> : null}
    </div>
  );
};

export default EnabledField;
