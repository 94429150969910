import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { H3 } from '../../text';
import { Card } from '../base';
import { FieldGroup } from '../field';

export const AnimatedDim = styled(motion.div)`
  z-index: 103;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a7a7a780;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const WindowCard = styled(Card.withComponent(motion.div))`
  border-radius: 16px;
  border: 1px solid var(--outline-var, #cdcbc9);
  border: 1px solid var(--outline-var, color(display-p3 0.8039 0.7961 0.7882));
  background: var(--surface-container-medium, #f6f1f0);
  background: var(
    --surface-container-medium,
    color(display-p3 0.9569 0.9451 0.9412)
  );
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 4px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow:
    0px 0px 20px 0px color(display-p3 0 0 0 / 0.05),
    0px 4px 10px -2px color(display-p3 0 0 0 / 0.1);
  width: 100%;
  max-width: 576px;
  max-height: 90%;
  margin: 24px;
  cursor: default;
  overflow: auto;
  ${FieldGroup} {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0;
  }
  margin-bottom: 32px;
`;

const headerHeight = 96;

export const WindowHeader = styled.div<{
  headerAlign: 'left' | 'center';
}>`
  z-index: 1;
  position: sticky;
  flex-direction: row-reverse;
  top: 0;
  display: flex;
  align-items: center;
  height: ${headerHeight}px;
  padding: 0px 32px 0 32px;

  > ${H3} {
    font-family: Barlow;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;

    width: 100%;
    text-align: ${({ headerAlign }) => headerAlign};
    margin-right: ${({ headerAlign }) =>
      headerAlign === 'center' ? '-40px' : 0};
    padding-right: ${({ headerAlign }) =>
      headerAlign === 'center' ? '40px' : 0};
    padding-left: ${({ headerAlign }) =>
      headerAlign === 'center' ? '40px' : 0};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const WindowChildren = styled.div<{
  hideTitleBar: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: ${({ hideTitleBar }) =>
    !hideTitleBar ? `calc(100% - ${headerHeight}px)` : '100%'};
`;
