import {
  useEntries,
  useDeleteEntity,
  useUpdateEntity,
  useArtist,
  defaultArtistNest,
  useCreateEntity,
  useEntry,
  useQuery,
  defaultReleaseNest,
} from 'imddata';
import type { EntityModels, NestDefinition, ReleaseNested } from 'imddata';
import type { ArtistRelease, FormValues } from './types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { difference } from 'ramda';
import { diff as objectDeepDiff } from 'deep-object-diff';

type ReleasePageNested = EntityModels.Nest<
  EntityModels.ReleasePage,
  { release?: ReleaseNested }
>;

const releasePageNest: NestDefinition = [
  {
    key: 'release',
    getKey: (v) => v?.releaseId,
    collect: true,
    entity: 'releases',
    nest: defaultReleaseNest,
  },
];

// TODO use releasePages entity instead of release to get all available onces and avoid drafts
const useArtistPageReleases = (
  artistId: number
): [ArtistRelease[], boolean] => {
  const { query, queryHash } = useQuery({
    query: {
      'filter.artists': artistId,
      'filter.is_active': true,
      perPage: 100,
    },
  });
  const {
    entries,
    nestLoadFinshed,
    request: { loaded },
  } = useEntries<ReleasePageNested>({
    entity: 'releasePages',
    nest: releasePageNest,
    query,
    queryHash,
  });

  return [
    entries
      .sort((a, b) => {
        const dateA =
          a.releaseAt || a.release?.currentDeliveryBundle?.releaseAt;
        const dateB =
          b.releaseAt || b.release?.currentDeliveryBundle?.releaseAt;

        if (dateA && dateB) {
          return new Date(dateB).valueOf() - new Date(dateA).valueOf();
        }
        return 0;
      })
      .map<ArtistRelease>((r) => ({
        id: undefined,
        enabled: true,
        title: r.release?.title + '',
        pageKey: r.url || '',
        cover: {
          url: r?.release?.covers.find((c) => c.type === 'small')?.file
            ?.downloadUrl,
          filename: 'cover',
          extension: 'jpg',
          access: 'public',
        },
        releaseAt: r.release?.originalReleaseDate || '',
        releaseTypeId:
          r.release && r.release.tracksCount > 0
            ? r.release.releaseTypeId
            : null,
        releasePageId: r.id || '',
      })),
    nestLoadFinshed && loaded,
  ];
};
const artistWithFiles: NestDefinition = [
  ...defaultArtistNest,
  {
    key: 'files',
    nest: [
      { key: 'file', entity: 'files', getKey: (e) => e?.fileId, collect: true },
    ],
  },
];

export const nestGalleryFiles: NestDefinition = [
  {
    key: 'images',
    nest: [
      { key: 'file', entity: 'files', getKey: (e) => e?.fileId, collect: true },
    ],
  },
];

type NestedImage = EntityModels.Nest<
  EntityModels.ArtistImage,
  { file?: EntityModels.File }
>;

export const mapGalleryImage = (image: NestedImage) => ({
  id: image.id,
  description: image.description,
  file: {
    url: image.file?.downloadUrl,
    filename: image.file?.fileName,
  },
});

export type ArtistGalleryNested = EntityModels.Nest<
  EntityModels.ArtistGallery,
  {
    images: NestedImage[];
  }
>;
export const useArtistPageForm = (artistId: number) => {
  const initilized = useRef(false);
  const [releases, loadedReleases] = useArtistPageReleases(artistId);

  const { createEntry: createArtistPage, createdId: createdArtistPageId } =
    useCreateEntity({
      entity: 'artistPages',
    });

  const { query, queryHash } = useQuery({
    query: { 'filter.artists': artistId, with: 'images' },
  });
  const {
    entries: galleries,
    request: galleryRequest,
    nestLoadFinshed: galleryImageLoaded,
  } = useEntries<ArtistGalleryNested>({
    nest: nestGalleryFiles,
    entity: 'artistGalleries',
    query,
    queryHash,
  });

  const galleriesLoaded = galleryRequest.failed || galleryRequest.loaded;

  const {
    entry: artist,
    nestLoadFinshed: artistFilesLoaded,
    // refresh,
  } = useArtist({
    nest: artistWithFiles,
    id: artistId,
  });
  useEffect(() => {
    if (!artist) return;
    if (!artist?.states?.artistHub?.artistPage?.id) {
      createArtistPage({
        data: { artistId },
      });
    }
  }, [!!artist]);
  const artistPageId: string =
    artist?.states?.artistHub?.artistPage?.id ||
    (createdArtistPageId as string);

  const { entry: artistPage } = useEntry<EntityModels.ArtistPage>({
    entity: 'artistPages',
    passive: !artistPageId,
    id: artistPageId,
  });

  const { entry: artistPageSplashFile } = useEntry<EntityModels.File>({
    entity: 'files',
    passive: !artistPage?.tourPageSplashFileId,
    id: artistPage?.tourPageSplashFileId,
  });

  const initialValues = useMemo<null | FormValues>(() => {
    if (!artist || !artistPage || !loadedReleases || !galleriesLoaded)
      return null;
    // To avoid nulling a form when files are added
    if (
      !initilized.current &&
      (!artistFilesLoaded ||
        !galleryImageLoaded ||
        (!artistPageSplashFile && artistPage.tourPageSplashFileId))
    )
      return null;
    initilized.current = true;

    const values: FormValues = {
      branded: false,
      locale: artistPage.locale,
      profileImage: artist.profileImageFile
        ? {
            filename: artist.profileImageFile.fileName,
            extension: artist.profileImageFile.extension,
            access: 'public',
            url: artist.profileImageFile.downloadUrl,
          }
        : undefined,
      name: artist.defaultName?.name || '',
      description: artist.biography,
      settings: {
        colors: {
          theme: artistPage.settings?.colors?.theme || 'auto',
          background:
            artistPage.settings?.colors?.background || 'var(--bg-mono)',
          accent: artistPage.settings?.colors?.accent || 'var(--accent-mono)',
        },
      },
      tour: {
        enabled: artistPage.enableTourPage,
        splash: {
          enabled: artistPage.tourPageSplashEnabled,
          description: artistPage.tourPageSplashDescription,
          title: artistPage.tourPageSplashTitle,
          url: artistPage.tourPageSplashUrl,
          image: artistPageSplashFile
            ? {
                filename: artistPageSplashFile.fileName,
                extension: artistPageSplashFile.extension,
                access: 'public',
                url: artistPageSplashFile.downloadUrl,
              }
            : undefined,
        },
        events: artist.tourDates,
      },
      media: artistPage.items
        .filter(
          (m) =>
            m.type === 'image-gallery' ||
            m.type === 'track' ||
            m.type === 'video-embed'
        )
        .map((m) =>
          m.type === 'image-gallery'
            ? {
                type: m.type,
                data: {
                  artistGalleryId: m.data.galleryId,
                  gallery: galleries
                    .find((g) => g.id === m.data.galleryId)
                    ?.images.map(mapGalleryImage),
                },
              }
            : m
        ),
      mediaLinks: artistPage.items
        .filter((m) => m.type === 'social' || m.type === 'custom-link')
        .map((m) =>
          m.type === 'image-gallery'
            ? {
                type: m.type,
                data: {
                  artistGalleryId: m.data.galleryId,
                  gallery: galleries
                    .find((g) => g.id === m.data.galleryId)
                    ?.images.map(mapGalleryImage),
                },
              }
            : m
        ),
      releases: releases.map((r) => {
        return {
          ...r,
          enabled: !!artistPage.releasePages.find(
            (arp) => arp.releasePageId === r.releasePageId
          ),
        };
      }),
      press: {
        enabled: artistPage.enablePressPage,
        pressFiles: artist.files.map((f) => ({
          id: f.id,
          description: f.description,
          file: f.file
            ? {
                id: f.file?.id,
                access: '',
                extension: f.file?.extension,
                filename: f.file?.fileName,
                size: f.file?.size,
              }
            : undefined,
        })),
        contacts: artist.contacts,
        quotes: artist.quotes,
      },
    };
    return values;
  }, [
    loadedReleases,
    artistId,
    !!artistPage,
    artistFilesLoaded,
    galleriesLoaded,
    galleryImageLoaded,
  ]);

  const { updateEntry: updateArtist } = useUpdateEntity({
    entity: 'artists',
    id: artistId,
  });

  const { updateEntry: updateArtistPage } = useUpdateEntity({
    entity: 'artistPages',
    id: artistPageId,
  });
  const { updateEntry: updateArtistGallery } = useUpdateEntity({
    entity: 'artistGalleries',
  });

  const { deleteEntry: deleteGallery } = useDeleteEntity({
    entity: 'artistGalleries',
  });

  const change = useCallback(
    (
      values: Partial<FormValues>,
      _dispatch: any,
      _props: any,
      prevValues: Partial<FormValues>
    ) => {
      if (!prevValues.name) {
        return;
      }
      const {
        // locale,
        description,
        name,
        releases,
        media,
        mediaLinks,
        tour: { events, splash, enabled: enableTourPage } = {
          events: [],
          highlight: {},
        },
        press: { contacts, quotes, enabled: enablePressPage } = {
          contacts: [],
          quotes: [],
        },
      } = values;

      const diffed = objectDeepDiff(values, prevValues) as Partial<FormValues>;

      const {
        enabled: tourPageSplashEnabled,
        description: tourPageSplashDescription,
        title: tourPageSplashTitle,
        url: tourPageSplashUrl,
      } = splash || {};

      const artistPagePayload = {
        tourPageSplashDescription,
        tourPageSplashEnabled,
        tourPageSplashTitle,
        tourPageSplashUrl,
        enablePressPage,
        settings: values.settings,
        locale: values.locale,
        enableTourPage,
        ...(releases
          ? {
              releasePages: releases
                .filter((r) => r.enabled)
                .map((r) => ({ releasePageId: r.releasePageId, id: r.id })),
            }
          : {}),
        ...(media || mediaLinks
          ? {
              items: [
                ...(media
                  ? media.map((r) =>
                      r.type === 'image-gallery'
                        ? {
                            type: r.type,
                            data: { galleryId: r.data.artistGalleryId },
                          }
                        : r
                    )
                  : []),

                ...(mediaLinks ? mediaLinks : []),
              ],
            }
          : {}),
      };

      const shouldUpdateArtistPage = (
        ['releases', 'media', 'mediaLinks', 'locale', 'settings'] as const
      ).find((k) => diffed[k] !== undefined);

      if (
        shouldUpdateArtistPage ||
        diffed.tour?.splash !== undefined ||
        diffed.press?.enabled !== undefined ||
        diffed.tour?.enabled !== undefined
      ) {
        updateArtistPage(
          {
            id: artistPageId,
            data: artistPagePayload,
          },
          { debounce: true }
        );
      }

      const shouldUpdateArtist =
        (['name', 'description'] as const).find(
          (k) => diffed[k] !== undefined
        ) ||
        diffed?.press?.quotes ||
        diffed?.tour?.events ||
        diffed.press?.contacts;

      if (shouldUpdateArtist) {
        updateArtist(
          {
            data: {
              names: [{ languageId: artist?.defaultName?.languageId, name }],
              biography: description,
              tourDates: events,
              contacts,
              quotes,
            },
          },
          { debounce: true }
        );
      }

      // Gallery handling
      const currentGalleryIds = values.media
        ? values.media
            .filter((m) => m.type === 'image-gallery')
            .map((m) =>
              m.type === 'image-gallery' ? m.data.artistGalleryId : null
            )
        : null;
      const deletedGalleries =
        currentGalleryIds && prevValues.media
          ? difference(
              prevValues.media
                .filter((m) => m.type === 'image-gallery')
                .map((m) =>
                  m.type === 'image-gallery' ? m.data.artistGalleryId : null
                ),

              currentGalleryIds
            )
          : [];
      for (const artistGalleryId of deletedGalleries) {
        if (artistGalleryId) {
          deleteGallery({ id: artistGalleryId });
        }
      }

      if (currentGalleryIds) {
        for (const gid of currentGalleryIds) {
          const galleryIndex = values?.media?.findIndex(
            (m) => m.type === 'image-gallery' && m.data.artistGalleryId === gid
          );
          if (
            galleryIndex !== undefined &&
            galleryIndex >= 0 &&
            diffed?.media?.[galleryIndex]
          ) {
            const galleryCard = values?.media?.[galleryIndex];
            if (galleryCard?.type !== 'image-gallery') {
              return;
            }
            if (galleryCard.data.gallery) {
              updateArtistGallery({
                id: gid,
                data: {
                  artistImageIds: galleryCard.data.gallery.map((i) => i.id),
                },
              });
            }
          }
        }
      }
    },
    [artistId, artist?.defaultLanguageId, artistPageId]
  );

  return {
    artistPage,
    artistPageId,
    initialValues,
    change,
  };
};
