//
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useProductPriceFormatted } from '../../../../logic';
import { BodyS } from '@imus/base-ui';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 16px;
  }
  ${BodyS} {
    color: var(--on-surface, #1b1c1b);
  }
`;

const LegendRowColor = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 12px;
  background-color: ${({ id }) => {
    if (id === 'ultraexpressdelivery') {
      return 'var(--state-active-accent)';
    }
    if (id === 'expressdelivery') {
      return 'var(--state-active-on-surface)';
    }
    return 'none';
  }};
`;

const LegendRow = ({ option }) => {
  const { t } = useTranslation();
  const priceFormatted = useProductPriceFormatted(option.id);

  if (!priceFormatted) {
    return null;
  }

  if (!option.isAvailable) return null;

  return (
    <Wrapper>
      <LegendRowColor id={option.id} />

      <BodyS>
        {t(option.id, {
          context: `calendar-${option.isAvailable ? 'enabled' : 'disabled'}`,
        })}
      </BodyS>

      <BodyS style={{ marginLeft: 'auto' }}>{priceFormatted}</BodyS>
    </Wrapper>
  );
};

export default LegendRow;
