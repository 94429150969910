//
import { put, take, all, call, fork, select, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { normalize } from 'normalizr';
import { camelize, camelizeKeys } from 'humps';
import createWebSocketConnection from '../utils/createWebSocket';
import { debounceUpdate, getApiHandler } from './request';
import { selectEntityData } from '../selectors';
import { addCover } from '../actionTypes/releases';
import { entities } from '../actionTypes';
import { loadLoggedUser } from '../actionTypes/users';
import * as requestActions from '../actionTypes/request';
// import { types as devTypes } from 'redux/actions/dev';
import * as schemas from '../schemas';
import { actionTypes as authActionTypes } from '../actionTypes/auth';

const FETCH_RESOURCE = '@sockets_saga/FETCH_RESOURCE';

export const cachedEmitterFactory = () => {
  const cache = {};
  return (fn) => {
    return (event) => {
      if (!event.msgId) {
        fn(event);
      }
      if (!cache[event.msgId]) {
        cache[event.msgId] = true;
        fn(event);
      }
    };
  };
};

function socketWatcherFactory(events) {
  return function watcher(socket, emitter) {
    return eventChannel((emit) => {
      const handlres = events.reduce((acc, eventName) => {
        return {
          ...acc,
          [eventName]: emitter((event) => {
            emit({ type: eventName, payload: camelizeKeys(event) });
          }),
        };
      }, {});

      for (const eventName of events) {
        socket.on(eventName, handlres[eventName]);
      }

      const unsubscribe = () => {
        for (const eventName of events) {
          socket.off(eventName, handlres[eventName]);
        }
      };
      return unsubscribe;
    });
  };
}

const AUDIO_TRANSCODED = 'imd.audio-file-transcoded';

const audioWatcher = socketWatcherFactory([AUDIO_TRANSCODED]);
export function* handleTrackChannel(channel) {
  while (true) {
    const {
      payload: { preset, downloadUrl, trackId },
    } = yield take(channel);

    // yield put({
    //   type: devTypes.ADD_HANDLED_EVENT,
    //   payload: {
    //     type,
    //     event: payload
    //   }
    // });
    const audio = camelizeKeys({ [preset]: downloadUrl });
    yield put(
      entities.tracks.actions.updateLocalEntry({
        id: trackId,
        data: {
          previewStatus: 'finished',
          availableAudio: audio,
        },
      })
    );
  }
}

const COVER_CREATED = 'imd.cover-thumbnail-created';
const COVER_STATUS_CHANGE = 'imd.release-upload-status-changed';

const coverWatcher = socketWatcherFactory([COVER_CREATED, COVER_STATUS_CHANGE]);
export function* handleCoverChannel(channel) {
  while (true) {
    const { type, payload } = yield take(channel);
    // yield put({
    //   type: devTypes.ADD_HANDLED_EVENT,
    //   payload: {
    //     type,
    //     event: payload
    //   }
    // });
    if (type === COVER_CREATED) {
      const { releaseId, coverTypeName, downloadUrl } = payload;
      yield put(addCover(releaseId, camelize(coverTypeName), downloadUrl));
    }
    if (type === COVER_STATUS_CHANGE) {
      const { id: releaseId, msgId, socket, ...data } = payload;
      yield put(
        entities.releases.actions.updateLocalEntry({
          id: releaseId,
          data,
        })
      );
    }
  }
}

const notificationsUpdatedWatcher = socketWatcherFactory([
  'imd.notifications-update',
]);

const entityStateExist = (state, entity) => {
  const entityState = state.entities[entity];
  return entityState !== null && entityState !== undefined;
};

const selectIsPending = (entityId, entity) => (state) => {
  if (!entityId) return false;
  const pendingUpdates = state.requests.entities[entity]?.pendingUpdate;

  if (!pendingUpdates) return false;

  return pendingUpdates.find((id) => id === entityId) !== undefined;
};

const getQuery = (type) => {
  switch (type) {
    case 'tracks': {
      return {
        with: 'artists,publishers,contributors',
      };
    }
    case 'releases': {
      return {
        with: 'deliveries,artists,tracks',
      };
    }
    default: {
      return {};
    }
  }
};

export function* handleNotificationsUpdatedChannel(
  notificationsUpdatedChannel
) {
  while (true) {
    yield take(notificationsUpdatedChannel);

    yield put({
      type: FETCH_RESOURCE,
      payload: {
        entityKey: 'userNotifications',
      },
      meta: {
        preventKeysReset: true,
      },
    });
  }
}

const resourceUpdatedWatcher = socketWatcherFactory([
  'imd.resource-updated',
  'imd.state-updated',
]);

const getSchema = (resourceType) => {
  switch (resourceType) {
    case 'releases':
      return schemas.release;
    case 'countries':
      return schemas.country;
    case 'deliveries':
      return schemas.delivery;
    case 'deliveryBundleDeliveries':
      return schemas.deliveryBundleDelivery;
    default:
      return schemas[resourceType.slice(0, -1)];
  }
};

const optimizelyUpdatedWatcher = socketWatcherFactory([
  'imd.optimizely-data-file-updated',
]);

function* handleOptimizely(optimizelyChannel) {
  while (true) {
    const {
      payload: { url },
    } = yield take(optimizelyChannel);

    yield put({
      type: authActionTypes.optimizelyUpdate,
      payload: {
        url,
      },
    });
  }
}

function* handleSingleResourceUpdate({ id, payload, resourceType, updatedAt }) {
  const selector = (state) => selectEntityData(resourceType)(state, id);
  const entry = yield select(selector);
  if (!entry) return;

  const pending = yield select(selectIsPending(entry?.id, resourceType));

  const updateAction = entities[resourceType]?.actions?.update;

  if (payload && updateAction && entry) {
    const schema = getSchema(resourceType);
    const data = schema ? normalize(payload, schema) : payload;

    yield put(
      updateAction.success({
        response: data,
        rawResponse: payload,
        responseDetails: {
          updatedAt,
        },
        payload: {
          id,
        },
        meta: { sockets: true },
      })
    );
  } else if (!pending && entry) {
    yield put({
      type: FETCH_RESOURCE,
      payload: {
        id,
        entityKey: resourceType,
      },
    });
  }
}

function* legacyIdHandling({ ids, resourceType, updatedAt }) {
  if (ids.length > 1) {
    const selector = (state) => selectEntityData(resourceType)(state);
    const entityState = yield select(selector);
    const filteredIds = ids.filter((id) => entityState && !!entityState[id]);
    const query = { 'filter.ids': filteredIds.join(',') };
    const queryHash = JSON.stringify(query);

    if (filteredIds.length > 0) {
      yield put({
        type: FETCH_RESOURCE,
        payload: {
          entityKey: resourceType,
          query,
          queryHash,
        },
      });
    }
  }
  if (ids.length === 1) {
    const id = ids[0];
    yield* handleSingleResourceUpdate({ id, resourceType, updatedAt });
  }
}

export function* handleResourcesUpdatedChannel(resourceUpdatedChannel) {
  while (true) {
    const {
      payload: { payload, id, type, updatedAt },
    } = yield take(resourceUpdatedChannel);

    const resourceType = camelize(type);

    const isEntityStateExist = yield select((state) =>
      entityStateExist(state, resourceType)
    );
    if (isEntityStateExist) {
      if (Array.isArray(id)) {
        yield* legacyIdHandling({ ids: id, resourceType, updatedAt });
      } else {
        yield* handleSingleResourceUpdate({
          id,
          resourceType,
          updatedAt,
          payload,
        });
      }
    }
  }
}

function* fetchResource(action) {
  const {
    payload: { entityKey, query: payloadQuery = {}, queryHash, id },
    meta,
  } = action;
  const apiHandler = getApiHandler(entityKey, 'fetch');
  const query = { ...getQuery(entityKey), ...payloadQuery };
  if (apiHandler) {
    yield fork(
      apiHandler,
      {
        entityKey,
        id,
        query,
        queryHash,
        forceReload: true,
      },
      meta
    );
  }
}

const DOWNLOAD_READY = 'imd.download-ready';
const DOWNLOAD_FAILED = 'imd.download-failed';

const downloadWatcher = socketWatcherFactory([DOWNLOAD_FAILED, DOWNLOAD_READY]);

function* downloader(channel) {
  while (true) {
    const downloadEvent = yield take(channel);
    switch (downloadEvent.type) {
      case DOWNLOAD_READY: {
        yield put(
          entities.downloads.actions.updateLocalEntry({
            id: downloadEvent.payload.id,
            data: {
              status: 'ready',
              statusText: 'Ready',
              statusCode: 3,
              downloadUrl: downloadEvent.payload.downloadUrl,
            },
          })
        );
        break;
      }
      default: {
        break;
      }
    }
  }
}

const instantMasteringWatcher = socketWatcherFactory([
  'imd.instant-mastering-previews-progress',
  'imd.instant-mastering-progress',
]);

export function* handleInstantMastering(channel) {
  while (true) {
    const {
      type,
      payload: { instantMasteringId, progress },
    } = yield take(channel);

    if (type === 'imd.instant-mastering-previews-progress')
      yield put(
        entities.instantMasterings.actions.updateLocalEntry({
          id: instantMasteringId,
          data: {
            previewsProgress: progress,
          },
        })
      );

    if (type === 'imd.instant-mastering-progress')
      yield put(
        entities.instantMasterings.actions.updateLocalEntry({
          id: instantMasteringId,
          data: {
            masteringProgress: progress,
          },
        })
      );
  }
}

const creditWatcher = socketWatcherFactory(['imd.credit-updated']);

export function* handleCreditChannel(creditChannel) {
  while (true) {
    const creditUpdate = yield take(creditChannel);
    const customerId = yield select((state) => state.auth?.profile?.customerId);
    if (customerId) {
      yield put(
        entities.customers.actions.updateLocalEntry({
          id: customerId,
          data: creditUpdate,
        })
      );
    }
  }
}

const targetsWatcher = socketWatcherFactory(['imd.targets-updated']);

function* handleTargetsChannel(targetsChannel) {
  while (true) {
    const {
      payload: { targets, id, type },
    } = yield take(targetsChannel);

    const resourceType = camelize(type);

    const isEntityStateExist = yield select((state) =>
      entityStateExist(state, resourceType)
    );
    if (isEntityStateExist) {
      const selector = (state) => selectEntityData(resourceType)(state, id);
      const entry = yield select(selector);
      if (entry) {
        const updateAction = entities[resourceType]?.actions?.updateLocalEntry;
        if (updateAction) {
          yield put(
            updateAction({ id, data: { targets: camelizeKeys(targets) } })
          );
        }
      }
    }
  }
}

const SUBSCRIPTION_UPDATE = 'imd.subscription-started';

const subscriptionWatcher = socketWatcherFactory([SUBSCRIPTION_UPDATE]);

function* handleSubscriptionChannel(subChannel) {
  while (true) {
    yield take(subChannel);
    yield delay(2000);
    yield put(loadLoggedUser());
    yield put(requestActions.loadEntity('customerSubscriptionState'));
    yield put(requestActions.loadEntity('pricesCustomer'));
  }
}

function* defaultRunListeners(socket, cachedEmitter) {
  const creditChannel = yield call(creditWatcher, socket, cachedEmitter);
  const targetsChannel = yield call(targetsWatcher, socket, cachedEmitter);
  const subChannel = yield call(subscriptionWatcher, socket, cachedEmitter);
  const resourceUpdatedChannel = yield call(
    resourceUpdatedWatcher,
    socket,
    cachedEmitter
  );
  const notificationsUpdatedChannel = yield call(
    notificationsUpdatedWatcher,
    socket,
    cachedEmitter
  );
  const coverChannel = yield call(coverWatcher, socket, cachedEmitter);
  const optimizelyChannel = yield call(
    optimizelyUpdatedWatcher,
    socket,
    cachedEmitter
  );
  const audioChannel = yield call(audioWatcher, socket, cachedEmitter);
  const masteringChannel = yield call(
    instantMasteringWatcher,
    socket,
    cachedEmitter
  );

  const downloadChannel = yield call(downloadWatcher, socket, cachedEmitter);

  yield all([
    fork(handleTargetsChannel, targetsChannel),
    fork(handleSubscriptionChannel, subChannel),
    fork(handleCreditChannel, creditChannel),
    fork(downloader, downloadChannel),
    fork(handleResourcesUpdatedChannel, resourceUpdatedChannel),
    fork(handleNotificationsUpdatedChannel, notificationsUpdatedChannel),
    fork(handleCoverChannel, coverChannel),
    fork(handleTrackChannel, audioChannel),
    fork(handleOptimizely, optimizelyChannel),
    fork(handleInstantMastering, masteringChannel),
  ]);
}

function* connectToChannels(channels, ...listeners) {
  const cachedEmitter = cachedEmitterFactory();
  for (let i = 0; i < channels.length; i += 1) {
    const socket = yield call(createWebSocketConnection, channels[i]);
    console.log('[SOCKETS] Connecting user to channel ', channels[i]);
    for (let j = 0; j < listeners.length; j += 1) {
      yield fork(listeners[j], socket, cachedEmitter);
    }
  }
}

function* runListeners(...listeners) {
  while (true) {
    const broadcastChannels = yield select(
      (state) => state.auth.profile?.broadcastChannels
    );

    if (!broadcastChannels) {
      console.error('Trying to connect before profile is fetched');
      return;
    }

    yield call(
      connectToChannels,
      broadcastChannels,
      defaultRunListeners,
      ...listeners
    );
  }
}

export default function* root(...listeners) {
  yield fork(runListeners, ...listeners);
  yield debounceUpdate(1000, FETCH_RESOURCE, fetchResource);
}
