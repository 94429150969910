import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import { useCurrentCustomerSubscription } from 'imddata';
import { useHistory } from 'react-router-dom';
import type { LocationDescriptor } from 'history';
import { TextFormatted, Button, Icons, Caption, Content } from 'imdui';
import { MusicDistributionProductIcon } from '@imus/base-ui';
import {
  ControlsGrid,
  ControlsText,
  ControlsWrapper,
  UpsellMessage,
} from '../styles';
import { SubscriptionUpsellContext } from 'imdshared';
import moment from 'moment';
import type { DeliveryBundle } from 'imddata/types/entities';

type ContentProps = {
  status: string;
  bundle?: EntityModels.DeliveryBundle;
  releaseDeliveryStatus: EntityModels.Release['deliveryStatus'];
  releaseId: number;
};

export const MusicDistributionInfo: React.FC<ContentProps> = ({
  status,
  releaseDeliveryStatus,
  releaseId,
  bundle,
}) => {
  const { t } = useTranslation();
  const subscription = useCurrentCustomerSubscription();
  const { open: openUpsell } = useContext(SubscriptionUpsellContext);
  const history = useHistory();

  if (
    !bundle ||
    bundle.status === 'draft' ||
    releaseDeliveryStatus === 'taken_down'
  ) {
    return (
      <UpsellMessage>
        <MusicDistributionProductIcon />
        <div>
          <Content>{t('music-distribution')}</Content>
          <Caption>
            <TextFormatted text={t('music-distribution-card-description')} />
          </Caption>
        </div>
      </UpsellMessage>
    );
  }

  const subCommission =
    subscription?.subscription?.salesCommission !== undefined
      ? subscription?.subscription?.salesCommission
      : Number.MAX_VALUE;

  const salesCommission =
    bundle.salesCommissionRate !== null &&
      bundle.salesCommissionRate < subCommission
      ? bundle.salesCommissionRate
      : subCommission === Number.MAX_VALUE
        ? null
        : subCommission;

  const canUpgrade = salesCommission && salesCommission > 0;

  const releaseAt = bundle.releaseAt;

  return (
    <>
      <ControlsWrapper>
        <ControlsGrid>
          <ControlsText>
            <Content>{t('delivery-settings')}</Content>
            <Caption>{t('manage-delivery-settings')}</Caption>
          </ControlsText>
          <Button
            size="small"
            text={t('manage')}
            onClick={() => {
              history.push(`/library/releases/${releaseId}/delivery`);
            }}
          />
          <ControlsText>
            <Content>{t('commission-rate', { salesCommission })}</Content>
            <Caption>
              {canUpgrade
                ? t('upgrade-keep-more-earnings')
                : t('lowest-commission-available')}
            </Caption>
          </ControlsText>
          <Button
            size="small"
            text={t('upgrade')}
            disabled={!canUpgrade}
            onClick={() => {
              openUpsell({
                section: 'music-distribution',
                analytics: { detail: 'commission' },
              });
            }}
          />
        </ControlsGrid>
      </ControlsWrapper>
      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        {releaseAt && (
          <Caption>
            {t('digital-release-date')}: {moment(releaseAt).format('L')}
          </Caption>
        )}
        <Caption>{t(`delivery-bundle-${status}`)}</Caption>
      </div>
    </>
  );
};

type ReleaseRequirement = Pick<
  EntityModels.Release,
  | 'deliveryStatus'
  | 'id'
  | 'currentDeliveryBundleStatus'
  | 'currentDeliveryBundleId'
  | 'firstDeliveryBundleId'
  | 'states'
>;

const useReleaseMusicDistributionRoute = <
  R extends ReleaseRequirement & {
    currentDeliveryBundle?: { states?: DeliveryBundle['states'] };
  },
>(
  release: R
): [
    LocationDescriptor | null,
    string,
    'feedback-required' | 'edit' | 'upsell' | undefined,
  ] => {
  const { t } = useTranslation();
  const {
    id,
    currentDeliveryBundleStatus,
    currentDeliveryBundleId,
    deliveryStatus,
    currentDeliveryBundle,
    states,
  } = release;

  const orderCreated =
    ['unpaid', 'pending'].indexOf(currentDeliveryBundleStatus) >= 0;

  const invoiceId = currentDeliveryBundle?.states?.orderAndInvoices?.invoiceId;

  if (invoiceId && orderCreated) {
    return [`/account/invoices/${invoiceId}`, t('continue-payment'), undefined];
  }

  if (orderCreated) {
    return [`/order/overview`, t('continue-payment'), undefined];
  }

  if (currentDeliveryBundleId && currentDeliveryBundleStatus === 'draft') {
    return [`/order/md/${currentDeliveryBundleId}`, t('continue'), undefined];
  }

  const fieldUpdateRequest = states?.updateRequest?.find(
    (s) => s.status === 'requested'
  );

  if (fieldUpdateRequest) {
    return [
      `/update-requests/${fieldUpdateRequest.id}`,
      t('resolve-issue'),
      'feedback-required',
    ];
  }
  if (!currentDeliveryBundleId || deliveryStatus === 'taken_down') {
    return [
      {
        pathname: '/order/md/new',
        state: {
          releaseId: id,
        },
      },
      t('start'),
      undefined,
    ];
  }

  return [null, '', undefined];
};

export const MusicDistributionAction = <R extends ReleaseRequirement>({
  release,
}: {
  release: R;
}) => {
  const [route, text, type] = useReleaseMusicDistributionRoute(release);
  const history = useHistory();
  const handleClick = useMemo(
    () =>
      route
        ? () => {
          history.push(route);
        }
        : undefined,
    [route]
  );

  if (!text) return null;

  if (type === 'feedback-required') {
    return (
      <Button
        size="small"
        primary="error"
        icon={Icons.states.error}
        onClick={handleClick}
        text={text}
      />
    );
  }

  return (
    <Button
      primary={true}
      size="small"
      disabled={!route}
      onClick={handleClick}
      text={text}
    />
  );
};
