/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useRef,
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
  memo,
} from 'react';
import styled from '@emotion/styled';
import { Portal } from 'react-portal';
import FocusTrap from 'focus-trap-react';
import { DOMNodeContext, DropdownScrollProvider } from 'imdui';
import { LayoutContext } from '../ScreenLayout/contexts';
import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

const dimBefore = { x: '100%' };
const dimAfter = { x: '0%' };

const animConfig: Transition = {
  ease: [0.23, 1, 0.32, 1],
};

const SideWindowContainer = styled(motion.div) <{
  top: number;
  bottom: number;
  isOpen?: boolean;
  isLarge?: boolean;
}>`
  scrollbar-gutter: stable;
  z-index: 100;
  overflow: auto;
  position: fixed;
  flex: 1;
  top: ${({ top }) => top}px;
  right: 0;
  width: 100%;
  height: ${({ top, bottom }) => `calc(100% - ${top}px - ${bottom}px)`};
  background: var(--surface-container-medium);
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.06);
  border-left: 1px solid var(--fg-4, rgba(0, 0, 0, 0.1));

  &:not(:first-child) {
    max-width: calc(100% - 4px);
  }

  ${({ isLarge }) =>
    isLarge
      ? `
    @media (min-width: 704px) {
      width: 704px;
    }
    `
      : `
    @media (min-width: 512px) {
      & {
        width: 512px;
      }
        &:not(:first-child) {
          width: calc(512px - 4px);
        }
    }
    `}
`;

type Props = {
  testId?: string;
  isOpen?: boolean;
  isLarge?: boolean;
  children: React.ReactNode;
  className?: string;
  offset?: {
    top: number;
  };
};

const SideWindow: React.FC<Props> = ({
  isOpen,
  isLarge,
  className,
  testId,
  children,
  offset = { top: 0 },
}: Props) => {
  const {
    safeConstraints: { top, bottom },
  } = useContext(LayoutContext);
  const { sideWindowNode } = useContext(DOMNodeContext);

  const [fallbackFocusId] = useState(() => `sidewindow-${Date.now()}`);
  const [isDetached, setDetached] = useState(true);
  const [wrapper, setWrapper] = useState(null);

  const openRef = useRef(isOpen);

  useEffect(() => {
    let timeout: number | null = null;
    if (!isOpen && openRef.current) {
      timeout = window.setTimeout(() => {
        setDetached(true);
        openRef.current = false;
      }, 200);
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (isDetached && isOpen) {
      window.dispatchEvent(new Event('resize'));
      setDetached(false);
      openRef.current = true;
    }
  }, [isDetached, isOpen]);

  const focusTrapOptions = useMemo(
    () => ({
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
      fallbackFocus: `[data-focus-id=${fallbackFocusId}]`,
      allowOutsideClick: true,
    }),
    [fallbackFocusId]
  );

  const setWrapperRef = useCallback((node) => {
    if (node !== null) {
      setWrapper(node);
    }
  }, []);

  const onClickWindow = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  if (!sideWindowNode?.current) {
    return null;
  }
  return (
    <Portal key="main" node={sideWindowNode.current}>
      <AnimatePresence>
        {isDetached ? null : (
          <FocusTrap focusTrapOptions={focusTrapOptions}>
            <DropdownScrollProvider value={wrapper}>
              <SideWindowContainer
                tabIndex={-1}
                initial={dimBefore}
                animate={dimAfter}
                exit={dimBefore}
                transition={animConfig}
                ref={setWrapperRef}
                data-test-id={testId}
                data-focus-id={fallbackFocusId}
                isOpen={isOpen}
                isLarge={isLarge}
                className={className}
                onClick={onClickWindow}
                top={top || offset?.top || 0}
                bottom={bottom || 0}
              >
                {typeof children === 'function' ? children(wrapper) : children}
              </SideWindowContainer>
            </DropdownScrollProvider>
          </FocusTrap>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default memo(SideWindow);
